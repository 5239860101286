import React from "react";
import Table from "./Table";

const Dashboard = () => {

    return (
        <>
            <Table />
        </>
    );
}

export default Dashboard;